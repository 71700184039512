<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12 v-if="_.size(this.order) > 0">
      <v-card>
        <chat-whatsapp :userActives="this.userActives" :numberActive="numberActive"/>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { firestore } from '../../fireStore';
import ChatWhatsapp from '../whatsapp/chat.vue';

export default {
  name: 'Whatsapp',
  components: { ChatWhatsapp },
  props: ['order'],
  watch: {
    order(e) {
      if (this._.size(this.order) > 0) {
        const number = e.user.phone;
        this.getWhatsapp(number);
      }
    },
  },
  computed: {
    whatsappReverse() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this._.reverse(this.whatsapps);
    },
    numberActive() {
      if (this.order.user.phone !== '') {
        return `+${this.order.user.phone}`;
      }
      return false;
    },
  },
  methods: {
    activeMore() {
      this.clientActive = !this.clientActive;
    },
    getUsersActives() {
      const self = this;
      this.userActivesEvent = firestore.collection('usersActives')
        .onSnapshot((querySnapshot) => {
          const userActives = [];
          querySnapshot.forEach((doc) => {
            userActives.push(doc.data());
          });
          self.userActives = userActives;
        });
    },
  },
  data() {
    return {
      messages: [],
      userActivesEvent: null,
      userActives: [],
      loading: false,
      clientActive: false,
      message: '',
    };
  },
  mounted() {
    if (this._.size(this.order) > 0) {
      this.getUsersActives();
    }
    this.$store.dispatch('updateTitle', `WhatsApp - Pedido ${this.order.id}`);
  },
  beforeDestroy() {
    if (this.userActivesEvent !== null) {
      this.userActivesEvent();
    }
  },
};
</script>
